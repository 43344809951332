export default {
  en: {
    alertNotificationTriggered: 'An alert has been triggered for the activity',
    priorityAlertNotificationTriggered: 'A priority alert has been triggered for the activity',
    alertNotificationsDeleted: 'Notifications were successfully deleted',
    notificationAlertType: 'Alert',
    notificationPriorityAlert: 'Priority alert',
    notificationNewMessageType: 'New message from',
    newMessage: 'You have received a new message in the conversation',
    notificationDialog: 'Dialog:',
    notificationCallBackRequest: 'Patient Callback request',
    notificationSystemAlert: 'Virtuose Console System Alert',
    notificationSystem: 'System Notification',
    newCallBackRequest: 'A callback request has been received from',
    alertTypeData: 'Alert Type:',
    openNotification: 'View',
    patientRoomAlert: 'Room',
    notification_redirecting: 'Redirecting to notification details',
    markUnread: 'Mark as unread',
    markRead: 'Mark as read',
  },
  fr: {
    alertNotificationTriggered: "Une alerte a été déclenchée pour l'activité",
    priorityAlertNotificationTriggered: "Une alerte prioritaire a été déclenchée pour l'activité",
    alertNotificationsDeleted: 'Les notifications ont été supprimées avec succès',
    notificationAlertType: 'Alerte',
    notificationPriorityAlert: 'Alerte prioritaire',
    notificationNewMessageType: 'Nouveau message de',
    newMessage: 'Vous avez reçu un nouveau message dans la conversation',
    notificationDialog: 'Dialogue:',
    notificationCallBackRequest: 'Demande de rappel patient',
    notificationSystemAlert: 'Alerte système Virtuose Console',
    notificationSystem: 'Notification système',
    newCallBackRequest: 'Une demande de rappel a été reçue de la part de',
    alertTypeData: "Type d'alerte:",
    openNotification: 'Consulter',
    patientRoomAlert: 'Chambre',
    notification_redirecting: 'Redirection vers le détail de la notification',
    markUnread: 'Marquer non lu',
    markRead: 'Marquer lu',
  },
};
