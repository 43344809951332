import AccessGroupHome from './AccessGroupHome';

export default {
  moduleName: 'accessGroup',
  routes: [
    {
      path: '/accessgroup',
      name: 'AccessGroupHome',
      component: AccessGroupHome,
    },
  ],
};
