<template>
  <v-container>
    <AccessGroupGrid />
  </v-container>
</template>

<script>
import AccessGroupGrid from './AccessGroupGrid';
export default {
  name: 'AccessGroupHome',
  components: {
    AccessGroupGrid,
  },
};
</script>

<style scoped></style>
