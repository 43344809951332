var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('ErrorModal',{attrs:{"error":_vm.error},on:{"close-error-modal":function($event){_vm.error = null}}}),_c('v-row',[_c('v-col',[_c('div',{staticClass:"page-title"},[_vm._v(" "+_vm._s(_vm.$t('accessGroup.accessGroups'))+" ")])])],1),(_vm.$can(_vm.permissions.actions.add, _vm.permissions.subjects.accessGroup))?_c('v-row',[_c('v-col',[_c('v-btn',{ref:"addAccessGroup",attrs:{"color":"primary"},on:{"click":function($event){return _vm.editAccessGroupAction(null)}}},[_vm._v(" "+_vm._s(_vm.$t('add'))+" ")])],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('DataGrid',{attrs:{"items":_vm.accessGroups,"columns":_vm.columns,"grid-name":"accessGroupsGrid","show-select":false,"items-per-page":25,"data-loading":_vm.showWaitModal},scopedSlots:_vm._u([{key:`item.system`,fn:function({ item }){return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.system),callback:function ($$v) {_vm.$set(item, "system", $$v)},expression:"item.system"}})]}},{key:"item.actions",fn:function({ item }){return [(
              _vm.$can(_vm.permissions.actions.change, _vm.permissions.subjects.accessGroup) ||
              (_vm.$can(_vm.permissions.actions.view, _vm.permissions.subjects.accessGroup) && item.system)
            )?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({ref:`actions_${item.id}`,attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[(
                  _vm.$can(_vm.permissions.actions.change, _vm.permissions.subjects.accessGroup) ||
                  _vm.$can(_vm.permissions.actions.view, _vm.permissions.subjects.accessGroup)
                )?_c('v-list-item',{ref:`editAccessGroup_${item.id}`,on:{"click":function($event){return _vm.editAccessGroupAction(item.id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.system ? 'mdi-eye' : 'mdi-pencil'))])],1),(!item.system && _vm.$can(_vm.permissions.actions.change, _vm.permissions.subjects.accessGroup))?_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('edit')))]):_vm._e(),(item.system && _vm.$can(_vm.permissions.actions.view, _vm.permissions.subjects.accessGroup))?_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('view')))]):_vm._e()],1):_vm._e()],1)],1):_vm._e()]}}],null,true)})],1)],1),_c('AccessGroupModal',{attrs:{"access-group-id":_vm.selectedAccessGroupId,"show":_vm.showEditModal},on:{"update:show":function($event){_vm.showEditModal=$event},"refresh":function($event){return _vm.init()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }