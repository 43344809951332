<template>
  <div>
    <div v-for="(caregiverInfo, index) in formData.caregiverInfo" :key="index" class="mb-4">
      <v-row>
        <v-col cols="auto">
          <v-icon class="mt-4">mdi-human-male-male</v-icon>
        </v-col>

        <v-col>
          <v-row dense>
            <v-col>
              <v-text-field
                :id="getRefNameFromParent('caregiverLastName')"
                :ref="`caregiverLastName_${index}`"
                v-model="caregiverInfo.caregiverLastName"
                :label="$t('caregiverLastName')"
                filled
                :class="{
                  'required-indicator': !formData.hasDci && caregiverRequired,
                }"
                :rules="caregiverRequired ? [validationRules.required] : []"
                :disabled="formData.hasDci"
              />
            </v-col>

            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
              <v-text-field
                :id="getRefNameFromParent('caregiverFirstName')"
                :ref="`caregiverFirstName_${index}`"
                v-model="caregiverInfo.caregiverFirstName"
                :label="$t('caregiverFirstName')"
                filled
                :class="{
                  'required-indicator': !formData.hasDci && caregiverRequired,
                }"
                :rules="caregiverRequired ? [validationRules.required] : []"
                :disabled="formData.hasDci"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-combobox
                :id="getRefNameFromParent('caregiverRelation')"
                :ref="`caregiverRelation_${index}`"
                v-model="caregiverInfo.caregiverRelation"
                :items="caregiverRelations"
                filled
                clearable
                :no-data-text="$t('noDataAvailable')"
                :label="$t('caregiverRelation')"
                :class="{
                  'required-indicator': !formData.hasDci && caregiverRequired,
                }"
                :rules="caregiverRequired ? [validationRules.required] : []"
                :disabled="formData.hasDci"
              />
            </v-col>
            <v-col>
              <v-select
                :id="getRefNameFromParent('caregiverRole')"
                :ref="`caregiverRole_${index}`"
                v-model="caregiverInfo.caregiverRole"
                :items="caregiverRoles"
                item-text="name"
                item-value="id"
                filled
                :no-data-text="$t('noDataAvailable')"
                :label="$t('caregiverRole')"
                :class="{
                  'required-indicator': !formData.hasDci && caregiverRequired,
                }"
                :rules="caregiverRequired ? [validationRules.required] : []"
                :disabled="formData.hasDci"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
              <v-text-field
                :id="getRefNameFromParent('caregiverPhone')"
                :ref="`caregiverPhone_${index}`"
                v-model="caregiverInfo.caregiverPhone"
                :label="$t('phone')"
                filled
                :class="{
                  'required-indicator': !formData.hasDci && caregiverRequired,
                }"
                :rules="caregiverRequired ? [validationRules.required] : []"
                :disabled="formData.hasDci"
              />
            </v-col>

            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
              <v-text-field
                :id="getRefNameFromParent('caregiverEmail')"
                :ref="`caregiverEmail_${index}`"
                v-model="caregiverInfo.caregiverEmail"
                :label="$t('email')"
                filled
                validate-on-blur
                :class="{
                  'required-indicator': !formData.hasDci && caregiverRequired,
                }"
                :rules="caregiverRequired ? [validationRules.email] : []"
                :disabled="formData.hasDci"
              />
            </v-col>
          </v-row>
          <v-row v-if="proximiteIsAvailable" dense>
            <div v-if="caregiverInfo.relationConfirmed" class="d-flex flex-fill pb-4 ml-1">
              <img src="/static/img/Proximite-100px.png" alt="logo proximite" />
              <p class="ml-4 mt-1 mb-0">{{ $t('relationConfirmed') }}</p>
            </div>
            <div v-else class="d-flex flex-fill ml-1">
              <v-icon>mdi-cancel</v-icon>
              <p class="ml-2 mt-1 mb-1">{{ $t('relationNotConfirmed') }}</p>
            </div>
          </v-row>
        </v-col>
        <v-col v-if="!formData.hasDci" cols="auto" align-self="center">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                :ref="`caregiverDelete_${index}`"
                color="primary"
                icon
                :disabled="cannotDeleteCaregiver()"
                @click="deleteCaregiver(index)"
                v-on="on"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            {{ $t('delete') }}
          </v-tooltip>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import accessibility from '@/accessibilityMixin';
import translation from '@/translationMixin';
import validationRulesMixin from '@/validationRulesMixin';
import { mapGetters } from 'vuex';
import { CaregiverFormData } from '../CaregiverFormData';

export default {
  name: 'CaregiverInfoTab',
  mixins: [translation, accessibility, validationRulesMixin],
  props: {
    formDataProps: {
      type: Object,
      required: true,
      default: () => {},
    },
    caregiverRoles: {
      type: Array,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      formData: this.formDataProps,
    };
  },
  computed: {
    ...mapGetters(['proximiteIsAvailable']),
    caregiverRequired() {
      return (
        this.formData.caregiverInfo[0]?.caregiverLastName ||
        this.formData.caregiverInfo[0]?.caregiverFirstName ||
        this.formData.caregiverInfo[0]?.caregiverPhone ||
        this.formData.caregiverInfo[0]?.caregiverEmail ||
        this.formData.caregiverInfo[0]?.caregiverRelation ||
        this.formData.caregiverInfo[0]?.caregiverRole
      );
    },
    caregiverRelations() {
      return [
        this.$t('daughter'),
        this.$t('son'),
        this.$t('spouse'),
        this.$t('daughterInLaw'),
        this.$t('sonInLaw'),
        this.$t('sister'),
        this.$t('brother'),
        this.$t('neighbor'),
        this.$t('friend'),
        this.$t('father'),
        this.$t('mother'),
      ].sort((a, b) => a.localeCompare(b));
    },
  },
  methods: {
    deleteCaregiver(caregiverIndex) {
      this.formData.caregiverInfo.splice(caregiverIndex, 1);
      if (this.formData.caregiverInfo.length === 0) {
        this.formData.caregiverInfo = [new CaregiverFormData()];
      }
    },

    cannotDeleteCaregiver() {
      return !(
        this.formData.caregiverInfo[0]?.caregiverEmail ||
        this.formData.caregiverInfo[0]?.caregiverFirstName ||
        this.formData.caregiverInfo[0]?.caregiverLastName ||
        this.formData.caregiverInfo[0]?.caregiverPhone ||
        this.formData.caregiverInfo[0]?.caregiverRelation ||
        this.formData.caregiverInfo[0]?.caregiverRole
      );
    },
  },
};
</script>
