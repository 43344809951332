<template>
  <v-container>
    <ErrorModal :error="error" @close-error-modal="error = null" />

    <v-row>
      <v-col>
        <div class="page-title">
          {{ $t('accessGroup.accessGroups') }}
        </div>
      </v-col>
    </v-row>

    <v-row v-if="$can(permissions.actions.add, permissions.subjects.accessGroup)">
      <v-col>
        <v-btn ref="addAccessGroup" color="primary" @click="editAccessGroupAction(null)">
          {{ $t('add') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <DataGrid
          :items="accessGroups"
          :columns="columns"
          grid-name="accessGroupsGrid"
          :show-select="false"
          :items-per-page="25"
          :data-loading="showWaitModal"
        >
          <template #[`item.system`]="{ item }">
            <v-simple-checkbox v-model="item.system" disabled />
          </template>

          <template #item.actions="{ item }">
            <v-menu
              v-if="
                $can(permissions.actions.change, permissions.subjects.accessGroup) ||
                ($can(permissions.actions.view, permissions.subjects.accessGroup) && item.system)
              "
            >
              <template #activator="{ on }">
                <v-btn :ref="`actions_${item.id}`" icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="
                    $can(permissions.actions.change, permissions.subjects.accessGroup) ||
                    $can(permissions.actions.view, permissions.subjects.accessGroup)
                  "
                  :ref="`editAccessGroup_${item.id}`"
                  @click="editAccessGroupAction(item.id)"
                >
                  <v-list-item-icon
                    ><v-icon>{{ item.system ? 'mdi-eye' : 'mdi-pencil' }}</v-icon></v-list-item-icon
                  >

                  <v-list-item-title
                    v-if="!item.system && $can(permissions.actions.change, permissions.subjects.accessGroup)"
                    >{{ $t('edit') }}</v-list-item-title
                  >
                  <v-list-item-title
                    v-if="item.system && $can(permissions.actions.view, permissions.subjects.accessGroup)"
                    >{{ $t('view') }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </DataGrid>
      </v-col>
    </v-row>
    <AccessGroupModal :access-group-id="selectedAccessGroupId" :show.sync="showEditModal" @refresh="init()" />
  </v-container>
</template>

<script>
import DataGrid from '@/components/DataGrid.vue';
import AccessGroupModal from '@/components/AccessGroup/AccessGroupModal.vue';
import translationMixin from '@/translationMixin';
import permissionMixin from '@/auth/permissionMixin';
import accessGroupService from '@/services/accessGroupService';

export default {
  name: 'AccessGroupGrid',
  components: { DataGrid, AccessGroupModal },
  mixins: [permissionMixin, translationMixin],
  props: {},
  data() {
    return {
      error: null,
      showWaitModal: false,
      showEditModal: false,
      accessGroups: [],
      selectedAccessGroupId: null,
    };
  },
  computed: {
    columns() {
      let columns = [
        {
          text: 'name',
          value: 'name',
          filterable: true,
        },
        {
          text: 'description',
          value: 'description',
          filterable: true,
        },
        {
          text: 'system',
          value: 'system',
          filterable: true,
        },
        {
          text: 'createdBy',
          value: 'createdBy',
          filterable: true,
          visible: false,
        },
        {
          text: 'createdDate',
          value: 'createdAt',
          filterable: false,
          visible: false,
        },
        {
          text: 'modifiedBy',
          value: 'updatedBy',
          filterable: true,
          visible: false,
        },
        {
          text: 'modifiedDate',
          value: 'updatedAt',
          filterable: false,
          visible: false,
        },
      ];
      if (
        this.$can(this.permissions.actions.view, this.permissions.subjects.accessGroup) ||
        this.$can(this.permissions.actions.change, this.permissions.subjects.accessGroup)
      ) {
        columns.push({
          text: 'actions',
          value: 'actions',
          filterable: false,
          sortable: false,
        });
      }
      return columns;
    },
  },
  created: async function () {
    await this.init();
  },
  methods: {
    init: async function () {
      this.showWaitModal = true;
      try {
        let accessGroups = await accessGroupService.getAccessGroups();
        this.accessGroups = accessGroups.map((accessGroup) => {
          return {
            id: accessGroup.id,
            name: accessGroup.name,
            description: accessGroup.description,
            system: accessGroup.system,
            createdBy: accessGroup.createdBy ? accessGroup.createdBy.username : '',
            createdAt: accessGroup.createdAt,
            updatedBy: accessGroup.updatedBy ? accessGroup.updatedBy.username : '',
            updatedAt: accessGroup.updatedAt,
          };
        });
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
    },
    editAccessGroupAction: function (accessGroupId) {
      this.selectedAccessGroupId = accessGroupId;
      this.showEditModal = true;
    },
  },
};
</script>

<style scoped></style>
