var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.formData.caregiverInfo),function(caregiverInfo,index){return _c('div',{key:index,staticClass:"mb-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{staticClass:"mt-4"},[_vm._v("mdi-human-male-male")])],1),_c('v-col',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-text-field',{ref:`caregiverLastName_${index}`,refInFor:true,class:{
                'required-indicator': !_vm.formData.hasDci && _vm.caregiverRequired,
              },attrs:{"id":_vm.getRefNameFromParent('caregiverLastName'),"label":_vm.$t('caregiverLastName'),"filled":"","rules":_vm.caregiverRequired ? [_vm.validationRules.required] : [],"disabled":_vm.formData.hasDci},model:{value:(caregiverInfo.caregiverLastName),callback:function ($$v) {_vm.$set(caregiverInfo, "caregiverLastName", $$v)},expression:"caregiverInfo.caregiverLastName"}})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : undefined}},[_c('v-text-field',{ref:`caregiverFirstName_${index}`,refInFor:true,class:{
                'required-indicator': !_vm.formData.hasDci && _vm.caregiverRequired,
              },attrs:{"id":_vm.getRefNameFromParent('caregiverFirstName'),"label":_vm.$t('caregiverFirstName'),"filled":"","rules":_vm.caregiverRequired ? [_vm.validationRules.required] : [],"disabled":_vm.formData.hasDci},model:{value:(caregiverInfo.caregiverFirstName),callback:function ($$v) {_vm.$set(caregiverInfo, "caregiverFirstName", $$v)},expression:"caregiverInfo.caregiverFirstName"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-combobox',{ref:`caregiverRelation_${index}`,refInFor:true,class:{
                'required-indicator': !_vm.formData.hasDci && _vm.caregiverRequired,
              },attrs:{"id":_vm.getRefNameFromParent('caregiverRelation'),"items":_vm.caregiverRelations,"filled":"","clearable":"","no-data-text":_vm.$t('noDataAvailable'),"label":_vm.$t('caregiverRelation'),"rules":_vm.caregiverRequired ? [_vm.validationRules.required] : [],"disabled":_vm.formData.hasDci},model:{value:(caregiverInfo.caregiverRelation),callback:function ($$v) {_vm.$set(caregiverInfo, "caregiverRelation", $$v)},expression:"caregiverInfo.caregiverRelation"}})],1),_c('v-col',[_c('v-select',{ref:`caregiverRole_${index}`,refInFor:true,class:{
                'required-indicator': !_vm.formData.hasDci && _vm.caregiverRequired,
              },attrs:{"id":_vm.getRefNameFromParent('caregiverRole'),"items":_vm.caregiverRoles,"item-text":"name","item-value":"id","filled":"","no-data-text":_vm.$t('noDataAvailable'),"label":_vm.$t('caregiverRole'),"rules":_vm.caregiverRequired ? [_vm.validationRules.required] : [],"disabled":_vm.formData.hasDci},model:{value:(caregiverInfo.caregiverRole),callback:function ($$v) {_vm.$set(caregiverInfo, "caregiverRole", $$v)},expression:"caregiverInfo.caregiverRole"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : undefined}},[_c('v-text-field',{ref:`caregiverPhone_${index}`,refInFor:true,class:{
                'required-indicator': !_vm.formData.hasDci && _vm.caregiverRequired,
              },attrs:{"id":_vm.getRefNameFromParent('caregiverPhone'),"label":_vm.$t('phone'),"filled":"","rules":_vm.caregiverRequired ? [_vm.validationRules.required] : [],"disabled":_vm.formData.hasDci},model:{value:(caregiverInfo.caregiverPhone),callback:function ($$v) {_vm.$set(caregiverInfo, "caregiverPhone", $$v)},expression:"caregiverInfo.caregiverPhone"}})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : undefined}},[_c('v-text-field',{ref:`caregiverEmail_${index}`,refInFor:true,class:{
                'required-indicator': !_vm.formData.hasDci && _vm.caregiverRequired,
              },attrs:{"id":_vm.getRefNameFromParent('caregiverEmail'),"label":_vm.$t('email'),"filled":"","validate-on-blur":"","rules":_vm.caregiverRequired ? [_vm.validationRules.email] : [],"disabled":_vm.formData.hasDci},model:{value:(caregiverInfo.caregiverEmail),callback:function ($$v) {_vm.$set(caregiverInfo, "caregiverEmail", $$v)},expression:"caregiverInfo.caregiverEmail"}})],1)],1),(_vm.proximiteIsAvailable)?_c('v-row',{attrs:{"dense":""}},[(caregiverInfo.relationConfirmed)?_c('div',{staticClass:"d-flex flex-fill pb-4 ml-1"},[_c('img',{attrs:{"src":"/static/img/Proximite-100px.png","alt":"logo proximite"}}),_c('p',{staticClass:"ml-4 mt-1 mb-0"},[_vm._v(_vm._s(_vm.$t('relationConfirmed')))])]):_c('div',{staticClass:"d-flex flex-fill ml-1"},[_c('v-icon',[_vm._v("mdi-cancel")]),_c('p',{staticClass:"ml-2 mt-1 mb-1"},[_vm._v(_vm._s(_vm.$t('relationNotConfirmed')))])],1)]):_vm._e()],1),(!_vm.formData.hasDci)?_c('v-col',{attrs:{"cols":"auto","align-self":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({ref:`caregiverDelete_${index}`,refInFor:true,attrs:{"color":"primary","icon":"","disabled":_vm.cannotDeleteCaregiver()},on:{"click":function($event){return _vm.deleteCaregiver(index)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('delete'))+" ")])],1):_vm._e()],1)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }