export default {
  en: {
    deletePatientQuestion: 'Are you sure you want to delete this patient ?',
    patients: 'Patients',
    patient: 'Patient',
    nasm: 'Health Insurance Number',
    birthDate: 'Birth Date',
    deathDate: 'Date of death',
    facility: 'Facility',
    serviceStartDate: 'Service Start Date',
    serviceEndDate: 'Service End Date',
    monitoringStart: 'Monitoring Start',
    monitoringEnd: 'Monitoring End',
    monitoringStartDate: 'Monitoring Start Date',
    monitoringEndDate: 'Monitoring End Date',
    city: 'City',
    country: 'Country',
    province: 'Province',
    department: 'Department',
    createdAt: 'Created at',
    externalId: 'External ID',
    patientType: 'Patient Type',
    roombed: 'Bed Number',
    language: 'Language',
    errorLanguageDoesNotExist: 'Language does not exists',
    daughter: 'Daughter',
    son: 'Son',
    spouse: 'Spouse',
    daughterInLaw: 'Daughter-in-law',
    sonInLaw: 'Son-in-law',
    sister: 'Sister',
    brother: 'Brother',
    neighbor: 'Neighbor',
    friend: 'Friend',
    father: 'Father',
    mother: 'Mother',

    createPatient: 'Add Patient',
    editPatient: 'Edit Patient',
    availableActivities: 'Available Activities',
    patientMonitoring: 'Clinical Overview',
    patientVitalSigns: 'Vital Signs (RT)',
    monitoring: 'Monitoring',

    caregiver: 'Caregiver',
    caregivers: 'Caregivers',
    caregiverFirstName: 'Last Name',
    caregiverLastName: 'First Name',
    caregiverRelation: 'Relationship',
    caregiverRole: 'Role',
    caregiverPhone: 'Phone',
    caregiverEmail: 'Email',
    addCaregiver: 'Add A Caregiver',

    statusLevels: 'Monitoring Values',
    Alert: 'Alert',
    NoFollowing: 'No following',
    NotCompleted: 'Not completed',
    Warning: 'Warning',
    Warning4h: 'No data for 4h or more',
    Warning24h: 'No data for 24h or more',
    Dry: 'OK',
    Wet: 'Some urine',
    Error: 'Error',
    Change: 'Consider change',
    timeFilterType: 'Time Filter Type',
    filter24h: 'Last 24 Hours',
    filterdays: 'Last Days',
    filterhours: 'Last Hours',
    filterrange: 'Range',
    nbDays: 'Days',
    nbHours: 'Hours',
    messages: 'Messages',
    communications: 'Communications',
    legend: 'Legend',
    incontinence: 'Incontinence',
    standardValues: 'Standard Values',
    fdeValues: 'Fall Detection Values',
    fallDetected: 'Fall detected',
    noFallDetected: 'No fall detected',

    errorFirstNameMustBeProvided: 'First name must be provided',
    errorLastNameMustBeProvided: 'Last name must be provided',
    errorBirthDateMustBeProvided: 'Birth data must be provided',
    errorNasmMustBeProvided: 'Health insurance number must be provided',
    errorInvalidNasm: 'Health insurance number is invalid',

    senderLastName: 'Last Name',
    senderFirstName: 'First Name',
    relationConfirmed: 'This caregiver is linked to this patient in Proximité',
    relationNotConfirmed: 'This caregiver is not linked to this patient in Proximité',
    messageDatetime: 'Received at',
    messageContent: 'Message Content',
    responseDatetime: 'Sent at',
    responseContent: 'Health Professional Response',
    answeredBy: 'Answered by',
    answer: 'Answer',
    newMessage: 'New',

    senderMessage: 'Sender Message',
    recipientMessage: 'Health Professional Message',
    send: 'Send',
    answerResidentMessage: 'Answer Message',
    actions: 'Actions',
    seeBtnTooltip: 'Read message',
    activities: 'Activities',
    communicators: 'Communicators',
    healthcareProfessionals: 'Healthcare Professionals',

    cohortMonitoring: 'Cohorts',
    takenMedication: 'Drug Taken',

    average: 'Average',
    minimum: 'Minimum',
    maximum: 'Maximum',
    valuesNumber: 'Number of values',
    weekOf: 'Week of',

    autoRefreshInterval: 'Automatic refresh',
    autoRefreshNone: 'No automatic refresh',
    autoRefreshOneMinute: 'Every minute',
    autoRefreshFiveMinutes: 'Every 5 minutes',
    autoRefreshFifteenMinutes: 'Every 15 minutes',

    lbs: 'lbs',

    variationAlertMessage: 'Alert',
    variationAlertContent: 'variation {{threshold}} over {{duration}}',

    clinicalData: 'Clinical Data',
    'baselines.height': 'Height',
    'baselines.weight': 'Weight',
    'baselines.heartRate': 'Heart Rate',
    'baselines.systolic': 'Systolic press.',
    'baselines.diastolic': 'Diastolic press.',
    'healthInfo.specificMedicalConditions': 'Specific Medical Conditions',
    'healthInfo.medicalConditions': 'Medical Conditions',
    'healthInfo.allergies': 'Allergies',
    'healthInfo.other': 'Other Information',

    patientSummary: 'Care Summary',
    generalInformation: 'General Information',
    contactInformation: 'Contact Information',
    admissionDetails: 'Admission Details',
    serviceDetails: 'Service Details',

    'monitoringEnd.activitiesAndProtocolsWarning': 'All activities and protocols will be removed from this patient',
    'monitoringEnd.connectedDevicesWarning': 'All connected devices will be removed from this patient',

    symo: 'SyMO',
    gustav: 'Gustav',
    alayacare: 'AlayaCare',
  },
  fr: {
    deletePatientQuestion: 'Êtes-vous certain de vouloir supprimer ce patient ?',
    patients: 'Patients',
    patient: 'Patient',
    nasm: "Numéro d'assurance maladie",
    birthDate: 'Date de naissance',
    deathDate: 'Date de décès',
    facility: 'Installation',
    serviceStartDate: 'Date de début de service',
    serviceEndDate: 'Date de fin de service',
    monitoringStart: 'Début de surveillance',
    monitoringEnd: 'Fin de surveillance',
    monitoringStartDate: 'Date de début de surveillance',
    monitoringEndDate: 'Date de fin de surveillance',
    city: 'Ville',
    country: 'Pays',
    province: 'Province',
    department: 'Département',
    createdAt: 'Date de création',
    updatedAt: 'Date de modification',
    externalId: 'Identifiant externe',
    patientType: 'Type de patient',
    roombed: 'Chambre-lit',
    language: 'Langue',
    errorLanguageDoesNotExist: "Cette langue n'existe pas",
    daughter: 'Fille',
    son: 'Fils',
    spouse: 'Conjoint(e)',
    daughterInLaw: 'Bru',
    sonInLaw: 'Gendre',
    sister: 'Soeur',
    brother: 'Frère',
    neighbor: 'Voisin(e)',
    friend: 'Ami(e)',
    father: 'Père',
    mother: 'Mère',

    createPatient: 'Ajouter un patient',
    endDatePatient: 'Fin épisode de soins',
    editPatient: 'Modifier un patient',
    availableActivities: 'Activités disponibles',
    patientMonitoring: 'Portrait clinique',
    patientVitalSigns: 'Signes vitaux (TR)',
    monitoring: 'Surveillance',

    caregiver: 'Proche aidant',
    caregiverFirstName: 'Prénom',
    caregiverLastName: 'Nom',
    caregiverRelation: 'Lien de parenté',
    caregiverRole: 'Rôle',
    caregiverPhone: 'Téléphone',
    caregiverEmail: 'Courriel',
    addCaregiver: 'Ajouter un proche aidant',

    statusLevels: 'Valeurs de surveillance',
    Alert: 'Alerte',
    NoFollowing: 'Pas de suivi',
    NotCompleted: 'Non complétée',
    Warning: 'Avertissement',
    Warning4h: 'Sans données pendant 4h ou plus',
    Warning24h: 'Sans données pendant 24h ou plus',
    Dry: 'OK',
    Wet: 'Humide',
    Error: 'Erreur',
    Change: 'Changer',
    timeFilterType: 'Type de filtre temporel',
    filter24h: 'Dernières 24 heures',
    filterdays: 'Nombre de jours précedents',
    filterhours: "Nombre d'heures précedentes",
    filterrange: 'Période',
    nbDays: 'Nombre de jours',
    nbHours: "Nombre d'heures",
    messages: 'Messages',
    communications: 'Communications',
    legend: 'Légende',
    incontinence: 'Incontinence',
    standardValues: 'Valeurs standard',
    fdeValues: 'Détection de la chute',
    fallDetected: 'Chute détectée',
    noFallDetected: 'Aucune chute détectée',

    errorFirstNameMustBeProvided: 'Le prénom doit être spécifié',
    errorLastNameMustBeProvided: 'Le nom doit être spécifié',
    errorBirthDateMustBeProvided: 'La date de naissance doit être spécifiée',
    errorNasmMustBeProvided: "Le numéro d'assurance maladie doit être spécifié",
    errorInvalidNasm: "Le numéro d'assurance maladie est invalide",

    senderLastName: 'Nom',
    senderFirstName: 'Prénom',
    relationConfirmed: 'Ce proche aidant est associé à ce patient dans Proximité',
    relationNotConfirmed: 'Ce proche aidant n’est pas associé à ce patient dans Proximité',
    messageDatetime: 'Reçu à',
    messageContent: 'Contenu du message',
    responseDatetime: 'Répondu à',
    responseContent: 'Réponse intervenant-e',
    answeredBy: 'Répondu par',
    answer: 'Répondre',
    newMessage: 'Nouveau',

    senderMessage: 'Message du correspondant',
    recipientMessage: "Message de l'intervenant-e",
    send: 'Envoyer',
    answerResidentMessage: 'Répondre au message',
    actions: 'Actions',
    seeBtnTooltip: 'Lire le message',
    activities: 'Activités',
    communicators: 'Communicateurs',
    healthcareProfessionals: 'Intervenants',

    cohortMonitoring: 'Cohortes',
    takenMedication: 'Médicament pris',

    average: 'Moyenne',
    minimum: 'Minimum',
    maximum: 'Maximum',
    valuesNumber: 'Nombre de valeurs',
    weekOf: 'Semaine du',

    autoRefreshInterval: 'Actualisation automatique',
    autoRefreshNone: 'Aucune actualisation',
    autoRefreshOneMinute: 'A chaque minute',
    autoRefreshFiveMinutes: 'Aux 5 minutes',
    autoRefreshFifteenMinutes: 'Aux 15 minutes',

    lbs: 'lb',
    variationAlertMessage: 'Alerte',
    variationAlertContent: 'variation {{threshold}} sur {{duration}}',

    clinicalData: 'Données cliniques',
    'baselines.height': 'Taille',
    'baselines.weight': 'Poids',
    'baselines.heartRate': 'Fréq. cardiaque',
    'baselines.systolic': 'Press. syst.',
    'baselines.diastolic': 'Press. diast.',
    'healthInfo.specificMedicalConditions': 'Conditions médicales particulières',
    'healthInfo.medicalConditions': 'Conditions médicales',
    'healthInfo.allergies': 'Allergies',
    'healthInfo.other': 'Autres Informations',

    patientSummary: 'Sommaire des soins',
    generalInformation: 'Information générale',
    contactInformation: 'Contact',
    admissionDetails: "Détails d'admission",
    serviceDetails: 'Détails des services',

    'monitoringEnd.activitiesAndProtocolsWarning':
      'Les activités et protocoles associés à ce(tte) patient(e) lui seront retirés',
    'monitoringEnd.connectedDevicesWarning': 'Les objets connectés à ce(tte) patient(e) lui seront seront retirés',

    symo: 'SyMO',
    gustav: 'Gustav',
    alayacare: 'AlayaCare',
  },
};
