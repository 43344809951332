import api from './baseApiService';

class AccessGroupService {
  async getAccessGroupNames() {
    return await api.get('accessgroupnames');
  }

  async getAccessGroups() {
    return await api.get('accessgroups');
  }
  async createAccessGroup(data) {
    return await api.post(`accessgroups/`, data);
  }
  async getAccessGroupById(accessGroupId) {
    return await api.get(`accessgroups/${accessGroupId}`);
  }
  async updateAccessGroup(accessGroupId, data) {
    return await api.put(`accessgroups/${accessGroupId}/`, data);
  }
  async getAllowedPermissions() {
    return await api.get('allowedpermissions/');
  }
}

export default new AccessGroupService();
